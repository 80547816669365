import '../css/index.scss';
import $ from "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle";

import "@nxcms/nxcms-js/src/js/static/index";
import "@nxcms/nxcms-js/src/js/blog/index";
import "@nxcms/nxcms-js/src/js/gallery/index";
import "@nxcms/nxcms-js/src/js/gdpr/index_bs5";

window.jQuery = window.$ = $;

$.fn.scrollToAddClass = function(className) {
    return this.each(function() {
        const $element = $(this);

        function isElementInViewport() {
            const rect = $element[0].getBoundingClientRect();
            return (
                rect.top <= (window.innerHeight || document.documentElement.clientHeight)
            );
        }

        function updateVisibility() {
            if (isElementInViewport()) {
                $element.addClass(className);
            } else {
                $element.removeClass(className);
            }
        }

        $(window).on('scroll', updateVisibility);
        updateVisibility();
    });
};

$(function() {
    $('.scroll-spy').scrollToAddClass('visible');
});
